import { NewsletterResponse } from './types/interfaces'
import { serialize } from './formHelpers'
import request from './request'

export const newsletter = (newsletterForm: HTMLFormElement | null): void => {
  if (newsletterForm != null) {
    newsletterForm.addEventListener('submit', event => {
      event.preventDefault()

      const btn = newsletterForm.querySelector('button')
      if (btn != null) {
        btn.disabled = true

        if (btn.getAttribute('data-disable-with') != null) {
          btn.textContent = btn.getAttribute('data-disable-with')
        }
      }

      const previousResponse:
        | HTMLParagraphElement[]
        | null = Array.prototype.slice.call(
          document.querySelectorAll('.newsletter__response')
        )
      if (previousResponse.length > 0) {
        previousResponse.forEach(response => response.remove())
      }

      const newsletterFormData: { [k: string]: string } = {}
      const fields = Array.prototype.slice.call(newsletterForm.elements, 0)
      Array.prototype.forEach.call(fields, function (element: {
        name: string
        value: string
      }) {
        newsletterFormData[element.name] = element.value
      })

      request(
        'POST',
        newsletterForm.action,
        serialize(newsletterFormData),
        function pluginNewsletterResponse (data: NewsletterResponse): void {
          const container = newsletterForm.parentElement
          if (container != null) {
            const responseMessage = document.createElement('p')
            responseMessage.className = 'newsletter__response'

            if (data.errors != null && data.errors.length > 0) {
              responseMessage.textContent = data.errors[0]
              container.insertAdjacentElement('afterbegin', responseMessage)
            }

            if (data.message != null) {
              responseMessage.textContent = data.message
              container.insertAdjacentElement('afterbegin', responseMessage)
            }

            newsletterForm.style.display = 'none'
          }
        }
      )
    })
  }
}
