import { serialize } from './formHelpers'
import request from './request'
import { RatingResponse } from './types/interfaces'

export const articleRating = (ratingForm: HTMLFormElement | null): void => {
  if (ratingForm != null) {
    const ratingBtns = ratingForm.getElementsByTagName('button')

    if (ratingBtns != null) {
      Array.prototype.forEach.call(ratingBtns, ratingBtn => {
        ratingBtn.addEventListener('click', (event: Event) => {
          event.preventDefault()

          const buttonClicked = event.currentTarget as HTMLButtonElement | null
          if (buttonClicked != null) {
            buttonClicked.classList.add('animate')

            Array.prototype.forEach.call(
              ratingBtns,
              (btn: HTMLButtonElement) => (btn.disabled = true)
            )

            const likeHiddenInput: HTMLInputElement | null = ratingForm.querySelector(
              '.like'
            )

            const buttonClickedValue = buttonClicked.getAttribute('data-like')

            if (buttonClickedValue === 'true' && likeHiddenInput != null) {
              likeHiddenInput.value = buttonClickedValue
            }
            const formData: { [k: string]: string } = {}
            const hiddenFormInputs: HTMLInputElement[] = Array.prototype.slice.call(
              ratingForm.querySelectorAll('input[type="hidden"]'),
              0
            )

            Array.prototype.forEach.call(hiddenFormInputs, function (element: {
              name: string
              value: string
            }) {
              formData[element.name] = element.value
            })

            const count = buttonClicked.querySelector('.count')
            if (count?.textContent != null) {
              const updateValue = String(parseInt(count.textContent, 10) + 1)
              count.textContent = updateValue
            }

            request(
              'POST',
              ratingForm.action,
              serialize(formData),
              pluginRatingResponse
            )
            setTimeout(() => {
              buttonClicked.classList.remove('animate')
            }, 2000)
          }
        })
      })
    }
  }
}

export function pluginRatingResponse (
  data: RatingResponse
): string | undefined {
  const responseOutput = document.querySelector('.rating__question')
  if (responseOutput != null) {
    if (!data.result && data.errors != null) {
      const dataErrors = data.errors
      return (responseOutput.textContent = dataErrors[0])
    } else if (data.message != null) {
      return (responseOutput.textContent = data.message)
    }
  }
  return undefined
}
